<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div style="overflow-x:hidden">
    <el-form v-show="showSearch" ref="queryForm" :model="queryParams" :inline="true"
      style="width: 100% ; margin: 10px 10px 10px 20px;">
      <el-form-item :label="$t('serverInspectionLog.label.status')" prop="status">
        <el-select v-model="queryParams.status" :placeholder="$t('serverInspectionLog.placeholder.status')" clearable
          size="small" style="width: 240px">
          <el-option v-for="dict in options" :key="dict.value" :label="dict.label" :value="dict.value" />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('serverInspectionLog.label.inspectedTime')">
        <el-date-picker v-model="dateRange" size="small" value-format="yyyy-MM-dd" type="daterange" range-separator="-"
          :start-placeholder="$t('serverInspectionLog.placeholder.inspectedTime.startTime')"
          :end-placeholder="$t('serverInspectionLog.placeholder.inspectedTime.endTime')" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" @click="handleSearch">
          {{ $t('operate.search') }}
        </el-button>
        <el-button type="primary" icon="el-icon-refresh" size="small" @click="resetSearch">
          {{ $t('operate.reset') }}
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 操作符 -->
    <el-row :gutter="10" class="mb8" style="width: 100% ; margin: 10px 10px 10px 20px;">
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-download" size="mini" :loading="exportLoading"
          @click="handleExport">{{ $t('operate.export') }}</el-button>
      </el-col>
    </el-row>
    <!--  表格数据  -->
    <el-table v-loading="loading" :data="tableData" :highlight-current-row="highlightCurrentRow"
      style="width: 100% ; margin: 10px 10px 10px 20px;" @row-click="handleTable">
      <el-table-column type="index" width="50" />
      <el-table-column prop="serverId" :label="$t('serverInspectionLog.label.serverId')" align="center" width="130"
        :show-overflow-tooltip="true" />
      <el-table-column prop="name" :label="$t('serverInspectionLog.label.name')" align="center" width="150"
        :show-overflow-tooltip="true" />
      <el-table-column prop="ipAddress" :label="$t('serverInspectionLog.label.ipAddress')" align="center" width="150"
        :show-overflow-tooltip="true" />
      <el-table-column prop="inspectedTime" :label="$t('serverInspectionLog.label.inspectedTime')" align="center"
        width="180" :show-overflow-tooltip="true" />
      <el-table-column prop="status" :label="$t('serverInspectionLog.label.status')" align="center" width="120"
        :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status === $t('serverInspectionLog.status.normal') ? '' : 'danger'">{{ scope.row.status
          }}</el-tag>
        </template></el-table-column>
      <el-table-column prop="cpuUsage" :label="$t('serverInspectionLog.label.cpuUsage')" align="center" width="130"
        :show-overflow-tooltip="true" />
      <el-table-column prop="networkTraffic" :label="$t('serverInspectionLog.label.networkTraffic')" align="center"
        width="130" :show-overflow-tooltip="true" />
      <el-table-column prop="diskSpaceUsage" :label="$t('serverInspectionLog.label.diskSpaceUsage')" align="center"
        width="130" :show-overflow-tooltip="true" />
      <el-table-column prop="memoryUsage" :label="$t('serverInspectionLog.label.memoryUsage')" align="center" width="120"
        :show-overflow-tooltip="true" />
      <el-table-column prop="alarmType" :label="$t('serverInspectionLog.label.alarmType')" align="center"
        :show-overflow-tooltip="true" />
    </el-table>
    <!--分页-->
    <div class="block" style="margin: 0 auto;text-align:center">
      <el-pagination :current-page="listQuery.page" :page-sizes="listQuery.pageSizes" :page-size="20"
        layout="total, sizes, prev, pager, next, jumper" background :total="total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" />
    </div>
    <!-- 对话框 -->
    <el-dialog :visible.sync="drawer" :with-header="false" width="620px">
      <el-form :data="drawerData" label-width="135px" :disabled="disabled" label-position="right">
        <h2>{{ $t('serverInspectionLog.title.serverInfo') }}</h2>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('server.label.serverId')" prop="id">
              <el-input v-model="drawerData.id" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.hostName')" prop="hostName">
              <el-input v-model="drawerData.hostName" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.serverName')" prop="name">
              <el-input v-model="drawerData.name" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.ipAddress')" prop="ip">
              <el-input v-model="drawerData.ipAddress" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.machineType')">
              <el-select v-model="drawerData.machineType" size="small">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.contactId')">
              <el-input v-model="drawerData.contactId" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.memory')" prop="memory">
              <el-input v-model="drawerData.memory" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('server.label.diskSpace')" prop="diskSpace">
              <el-input v-model="drawerData.diskSpace" size="small" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('server.label.inspectionConfig')">
              <el-input v-model="drawerData.inspectionConfig" type="textarea" size="small" />
            </el-form-item>
          </el-col>
        </el-row>
        <h2>{{ $t('serverInspectionLog.title.inspectionLogInfo') }}</h2>
        <el-row>
          <el-col :span="24"><el-form-item :label="$t('serverInspectionLog.label.id')" prop="id"><el-input
                v-model="drawerData.id" size="small" /></el-form-item></el-col>
          <el-col :span="24"><el-form-item :label="$t('serverInspectionLog.label.inspectedTime')"
              prop="inspectedTime"><el-input v-model="drawerData.inspectedTime" size="small" /></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('serverInspectionLog.label.cpuUsage')" prop="cpuUsage"><el-input
                v-model="drawerData.cpuUsage" size="small" /></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('serverInspectionLog.label.networkTraffic')"
              prop="networkTraffic"><el-input v-model="drawerData.networkTraffic" size="small" /></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('serverInspectionLog.label.diskSpaceUsage')"
              prop="diskSpaceUsage"><el-input v-model="drawerData.diskSpaceUsage" size="small" /></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('serverInspectionLog.label.usedDiskSpace')"
              prop="usedDiskSpace"><el-input v-model="drawerData.usedDiskSpace" size="small" /></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('serverInspectionLog.label.memoryUsage')"
              prop="memoryUsage"><el-input v-model="drawerData.memoryUsage" size="small" /></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('serverInspectionLog.label.usedMemory')" prop="usedMemory"><el-input
                v-model="drawerData.usedMemory" size="small" /></el-form-item></el-col>
          <el-col :span="12"><el-form-item :label="$t('serverInspectionLog.label.status')" prop="status">
              <el-tag :type="drawerData.status === $t('serverInspectionLog.status.normal') ? '' : 'danger'">{{
                drawerData.status }}</el-tag>
            </el-form-item></el-col>
          <el-col :span="24"><el-form-item :label="$t('serverInspectionLog.label.alarmType')" prop="alarmType"><el-input
                v-model="drawerData.alarmType" size="small" /></el-form-item></el-col>
          <el-col :span="24"><el-form-item :label="$t('serverInspectionLog.label.alarmMessage')"
              prop="alarmMessage"><el-input v-model="drawerData.alarmMessage" size="small" /></el-form-item></el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'ServerInspectionLog',
  data() {
    return {
      // 表格数据
      tableData: [],
      // 弹框详情信息
      drawerData: {},
      highlightCurrentRow: true,
      // 封装分页数据
      listQuery: {
        // 当前页数
        page: 1,
        // 一页最大的记录数，默认5条
        limit: 20,
        // 每页显示条数的选择器
        pageSizes: [20, 50, 100, 200]
      },
      drawer: false,
      // 总记录数
      total: 0,
      // 搜索框
      input: '',
      // 新增弹框控制
      insertdialogVidible: false,
      // 遮罩层
      loading: true,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        serverId: undefined,
        status: undefined
      },
      // 限制时间
      dateRange: [],
      // 显示搜索条件
      showSearch: true,
      // 状态数据字典
      statusOptions: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 导出遮罩层
      exportLoading: false,
      // 下拉框数据
      options: [
        {
          value: 1,
          label: '正常'
        },
        {
          value: 2,
          label: '异常告警'
        }
      ],
      // 控制表单是否可修改
      disabled: false
    }
  },
  created() {
    this.changeLocale()
    // 获取列表数据
    this.getList()
  },
  methods: {
    /**
    * @methods getList
    * @description 查询列表数据，渲染数据
    * @param {String} url '/v1.0/ServerService/servers'+params
    */
    getList() {
      this.loading = true
      this.$api.getServerInspectionLogList(this.queryParams).then(res => {
        console.log(res)
        res.data.list.forEach(item => {
          this.changeStatus(item)
          this.changeAlarmType(item)
          this.changeMachineType(item)
          item.inspectedTime = this.changeTime(item.inspectedTime)
        })
        // 将结果给列表
        this.tableData = res.data.list
        this.total = res.data.total
      })
      // 取消遮罩层
      this.loading = false
    },
    /**
    * @methods changeMachineType
    * @description 判断机器类型转换为i18n对应的字段
    * @param {Object} params 包含机器类型的数据
    */
    changeMachineType(item) {
      if (item.machineType === 1) {
        item.machineType = this.$t('server.machineType.physical')
      } else if (item.machineType === 2) {
        item.machineType = this.$t('server.machineType.virtual')
      } else if (item.machineType === 3) {
        item.machineType = this.$t('server.machineType.docker')
      }
    },
    /**
    * @methods changeLocale
    * @description 修改i18n语言
    */
    changeLocale() {
      const language = this.$route.query.language
      if (language === 'en') {
        this.$i18n.locale = 'en_US'
        this.$set(this.options, 0, { value: 1, label: this.$t('serverInspectionLog.status.normal') })
        this.$set(this.options, 1, { value: 2, label: this.$t('serverInspectionLog.status.anomalous') })
        this.$store.commit('changeLanguage')
      } else {
        this.$i18n.locale = 'zh_CN'
      }
    },
    /**
    * @methods changeStatus
    * @description 转换巡检状态为i18n对应的字段
    * @param {Object} params 包含巡检状态的数据
    */
    changeStatus(item) {
      if (item.status === 1) {
        item.status = this.$t('serverInspectionLog.status.normal')
      } else if (item.status === 2) {
        item.status = this.$t('serverInspectionLog.status.anomalous')
      }
    },
    /**
    * @methods changeAlarmType
    * @description 将告警类型转换为对应i8n的字段
    * @param {Object} params 包含告警类型的数据
    */
    changeAlarmType(item) {
      if (item.alarmType === 'CPU_USAGE_TOO_HIGH') {
        item.alarmType = this.$t('serverInspectionLog.alarmType.cpuUsage')
      } else if (item.alarmType === 'MEMORY_USAGE_TOO_HIGH') {
        item.alarmType = this.$t('serverInspectionLog.alarmType.memory')
      } else if (item.alarmType === 'ABNORMAL_NETWORK_TRAFFIC') {
        item.alarmType = this.$t('serverInspectionLog.alarmType.networkTraffic')
      } else if (item.alarmType === 'ABNORMAL_DISK_IO') {
        item.alarmType = this.$t('serverInspectionLog.alarmType.diskIO')
      }
    },
    /**
    * @methods resetQuery
    * @description 重置查询参数
    */
    resetQuery() {
      this.queryParams = {
        pageIndex: 1,
        pageSize: 20,
        ipAddress: undefined,
        serverId: undefined,
        startTime: undefined,
        endTime: undefined,
        status: undefined
      }
      this.dateRange = []
    },
    /**
    * @methods changeTimeByZone
    * @description 修改显示时间为对应时区
    * @param {Object} params 时间
    * @return {Promise} 修改后的时间
    */
    // changeTimeByZone(time0) {
    //   const moment = require('moment')
    //   // 显示的时间格式
    //   const formatString = 'YYYY-MM-DD HH:mm:ss'
    //   // 获取时区
    //   var timezone = (0 - new Date().getTimezoneOffset() / 60)
    //   // 将返回的时间转为时区
    //   return moment(time0).utcOffset(timezone).format(formatString)
    // },
    /**
     * @methods changeTime
     * @description 转换时间格式
     * @param {Object} params time 表示某一条时间数据
     */
    changeTime(time) {
      const moment = require('moment')
      // 显示的时间格式
      const formatString = 'YYYY-MM-DD HH:mm:ss'
      return moment(time).format(formatString)
    },
    /**
    * @methods handleSizeChange
    * @description 用户点击修改每页最大数触发
    */
    handleSizeChange(val) {
      // val 表示一页需要多少条数，pageSize
      this.queryParams.pageSize = `${val}`
      this.getList()
    },
    /**
    * @methods handleCurrentChange
    * @description 用户点击切换页数触发
    */
    handleCurrentChange(val) {
      // val 表示当前第几页,pageIndex
      this.queryParams.pageIndex = `${val}`
      this.getList()
    },
    /**
    * @methods handleSearch
    * @description 用户点击搜索时触发
    */
    handleSearch() {
      this.queryParams.pageIndex = 1
      if (this.dateRange.length === 2) {
        this.queryParams.startTime = this.dateRange[0]
        this.queryParams.endTime = this.dateRange[1]
      }
      console.log(this.queryParams)
      this.getList()
    },
    /**
    * @methods resetSearch
    * @description 重置搜索条件
    */
    resetSearch() {
      this.resetQuery()
      this.getList()
    },
    /**
    * @methods handleExport
    * @description 用户点击导出按钮触发
    */
    handleExport() {
      this.$api.exportServerInspectionLogAsExcelFile().then(res => {
        this.exportFile(this.$t('export.serverInspectionLog'), res)
      })
    },
    /**
    * @methods handleTable
    * @description 用户点击页面列表某一行触发
    * @param {Object} params 当前行信息
    */
    handleTable(row) {
      this.drawerData = row
      this.disabled = true
      this.drawer = true
    }
  }
}
</script>

<style lang="scss" scoped>
.evetn_input {
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
}
</style>
